import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import block from 'bem-cn';

import { actions as userActions } from 'features/users/redux';
import Input from 'components/Input';
import BackBlock from 'components/BackBlock/desktop';
import Spinner from 'components/Spinner';
import Button from 'components/Button';
/* import PopUp from 'features/users/view/UserEdit/PopUp'; */

import './ChangePas.scss';

const b = block('change-pas');

const ChangePas = () => {
  const dispatch = useDispatch();

  const { id } = useSelector(state => state.auth);
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const { actionProcessing } = useSelector(state => state.users, shallowEqual);

  const [oldPassword, editOldPassword] = useState('');
  const [newPassword, editNewPassword] = useState('');
  const [repeatNewPassword, editRepeatNewPassword] = useState('');
  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      dispatch(userActions.changePassword({ id, oldPassword, newPassword, repeatNewPassword }));
    },
    [id, newPassword, oldPassword, repeatNewPassword],
  );

  /* const [popUpIsOpen, setPopUpOpened] = useState(false);
  const [pas, setPas] = useState('');
  const [passwordDirty, setPasswordDirty] = useState(true);
  const [passwordError, setPasswordError] = useState(locale.errorPassword);
  const [isValid, setIsValid] = useState(false); */

  /* useEffect(() => {
    if (passwordError) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [passwordError]);

  const blureHandler = e => {
    switch (e.target.name) {
      case 'password':
        setPasswordDirty(true);
        break;
    }
  };

  const passwordHandler = e => {
    setPas(e.target.value);
    const re = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}/;
    if (!re.test(String(e.target.value))) {
      setPasswordError(locale.errorPassword);
      if (!e.target.value) {
        setPasswordError(locale.noPass);
      }
    } else {
      setPasswordError('');
    }
  };

  const onResetClick = useCallback(() => {
    dispatch(actions.resetPassword(id, setPopUpOpened, pas));
  }, [dispatch, id, pas]); */

  return (
    <section className={b()}>
      <Spinner isLoading={actionProcessing} />
      <section className={b('content')}>
        <h3 className={b('title')}>{locale.changePassword}</h3>
        <div className={b('main')}>
          <form className={b('form')} onSubmit={onSubmit}>
            <label className={b('item')}>
              <p className={b('item-title')}>{locale.oldPassword}</p>
              <div className={b('item-field')}>
                <Input
                  value={oldPassword}
                  name="old-password"
                  placeholder={locale.password}
                  type="password"
                  color="blue"
                  onChange={e => editOldPassword(e.currentTarget.value)}
                />
              </div>
            </label>

            <label className={b('item')}>
              <p className={b('item-title')}>{locale.newPassword}</p>
              <div className={b('item-field')}>
                <Input
                  value={newPassword}
                  name="new-password"
                  placeholder={locale.password}
                  type="password"
                  color="blue"
                  onChange={e => editNewPassword(e.currentTarget.value)}
                />
              </div>
            </label>

            <label className={b('item')}>
              <p className={b('item-title')}>{locale.confirmNewPassword}</p>
              <div className={b('item-field')}>
                <Input
                  value={repeatNewPassword}
                  name="repeat-new-password"
                  placeholder={locale.password}
                  type="password"
                  color="blue"
                  onChange={e => editRepeatNewPassword(e.currentTarget.value)}
                />
              </div>
            </label>

            <div className={b('button')}>
              <Button type="submit" color="orange">
                <span className={b('button-text')}>{locale.save}</span>
              </Button>
            </div>
          </form>
        </div>
      </section>
    </section>
  );
  /* return (
    <div className={b()}>
      {popUpIsOpen && (
        <PopUp
          password={pas}
          id={id}
          locale={locale}
          onClose={setPopUpOpened}
        />
      )}
      {passwordError && passwordDirty && (
        <div className={b("text-pass")} style={{ color: "red" }}>
          {passwordError}
        </div>
      )}
      <Input
        type="text"
        value={pas}
        onChange={(e) => passwordHandler(e)}
        placeholder={locale.theNewPassword}
        name="password"
        onBlur={(e) => blureHandler(e)}
      />
      <button
        className={b("button")}
        disabled={!isValid}
        onClick={onResetClick}
      >
        {locale.changePas}
      </button>
    </div>
  ); */
};

export default ChangePas;
