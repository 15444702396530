import EnPNG from './img/en.png';
import EsPNG from './img/sp.png';
import prPNG from './img/pr.png';

export const languages = {
  EN: 'en-US',
  ES: 'es-es',
  PT: 'pt-pt',
};

export const shortLanguages = {
  'en-US': 'en',
  'es-es': 'es',
  'pt-pt': 'pt',
};

export const languagesWithIcons = {
  'es-es': { lang: languages.ES, icon: EsPNG, text: 'Spanish' },
  'pt-pt': { lang: languages.PT, icon: prPNG, text: 'Portugal' },
  'en-US': { lang: languages.EN, icon: EnPNG, text: 'English' },
};
const rtlLangs = [];

export const checkIsRtlLang = lang => {
  return rtlLangs.includes(lang);
};
