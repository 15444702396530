import React, { useState } from 'react';
import block from 'bem-cn';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SVGInline from 'react-svg-inline';

import { actions as authActions } from 'features/Auth';
import SVG from 'components/SVG';
import PopUp from 'components/PopUp';
import Button from 'components/Button';

import language from './img/lang.svg';
import pas from './img/pas.svg';
import out from './img/out.svg';
import arrowSvg from './img/arrow.svg';

import './Footer.scss';

const b = block('footer');

const Footer = ({ locale, isAuth }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const handleLogOut = () => {
    setIsOpen(false);
    dispatch(authActions.logOut());
  }

  return (
    <footer className={b()}>
      {isOpen &&
      <PopUp
        title={locale.logout}
        onClose={() => setIsOpen(false)}
      >
        <h3 className={b('pop-up-title')}>{locale.wantLogout}</h3>
        <div className={b('pop-up-button')}>
          <Button color="logout-color" onClick={handleLogOut}>
            <div className={b('pop-up-button-text')}>
              {locale.yesLogout}
            </div>
          </Button>
        </div>
        <div className={b('pop-up-button')}>
          <Button onClick={() => setIsOpen(false)}>
            <div className={b('pop-up-button-text')}>
              {locale.noIStay}
            </div>
          </Button>
        </div>
      </PopUp>}
      <div className={b('title')}>{locale.other}</div>
      <Link to="/locale" className={b('item', { active: location.pathname.startsWith('/locale') })}>
        <SVG svgProps={{ svg: language }} className={b('item-img')} />
        <span className={b('item-text')}>{locale.changeLang}</span>
        <SVG className={b('element-arrow')} svgProps={{ svg: arrowSvg }} />
      </Link>
      {isAuth && (
        <Link to="/change-pas" className={b('item', { active: location.pathname.startsWith('/change-pas') })}>
          <SVG svgProps={{ svg: pas }} className={b('item-img')} />
          <span className={b('item-text')}>{locale.changePas}</span>
          <SVG className={b('element-arrow')} svgProps={{ svg: arrowSvg }} />
        </Link>
      )}
      {isAuth && (
        <div className={b('item')} onClick={() => setIsOpen(true)}>
          <SVG svgProps={{ svg: out }} className={b('item-img')} />
          <span className={b('item-text')}>{locale.logout}</span>
          <SVG className={b('element-arrow')} svgProps={{ svg: arrowSvg }} />
        </div>
      )}
    </footer>
  );
};

export default Footer;
