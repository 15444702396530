import React, { useMemo, useState } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';

import { languagesWithIcons } from 'services/locale';
import { actions as localeActions } from 'features/locale';
import Button from 'components/Button';

import './Locale.scss';

const b = block('locale-desktop');

const Locale = ({ forAuth = false, callback = () => null }) => {
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale);
  const lang = useSelector(state => state.locale.lang);

  const [activeLang, changeActivelang] = useState(lang);

  const langItems = useMemo(
    () =>
      Object.entries(languagesWithIcons).map(temp => (
        <div
          key={temp[0]}
          className={b('item', { active: activeLang === temp[0] })}
          onClick={() => changeActivelang(temp[0])}>
          <img src={temp[1].icon} alt="" className={b('item-flag')} />
          <span className={b('item-text')}>{temp[1].text}</span>
        </div>
      )),
    [lang, dispatch, activeLang],
  );

  const handleButtonClick = () => {
    dispatch(localeActions.changeLang(activeLang));
    callback();
  };

  return (
    <section className={b({ forAuth })}>
      <section className={b('content', { forAuth })}>
        {!forAuth && <h3 className={b('title')}>{locale.changeLanguage}</h3>}
        {langItems}
        <div className={b('bottom', { forAuth })}>
          <div className={b('button')}>
            <Button onClick={handleButtonClick} color="orange">
              <span className={b('button-text')}>{locale.changeLang}</span>
            </Button>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Locale;
