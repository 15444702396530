import React, { useEffect } from 'react';
import block from 'bem-cn';
import { Route, Switch, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { actions as authAction } from 'features/Auth';
import Notify from 'features/notify/view/desktop';
import CreateUser from 'features/users/view/desktop/CreateUser';
import UsersList from 'features/users/view/desktop/UsersList';
import UserEdit from 'features/users/view/desktop/UserEdit';
import ChangePas from 'features/ChangePas/desktop';
import Total from 'features/Total/view/desktop';
import Tree from 'features/tree/view/desktop';
import Bets from 'features/bets/view/desktop';
import CashierJackpot from 'features/cashierJackpot/view/desktop';
import SlotsBetHistory from 'features/slotsBetHistory/view/desktop';
import TransactionHistory from 'features/transaction/view/desktop/TransactionHistory';
import ChangeChildPas from 'features/ChangeChildPas/desktop';

import Footer from 'components/Footer/desktop';
import Header from 'components/Header/desktop';
import AsyncPollBalance from 'components/AsyncPoll/AsyncPollBalance';

import Locale from 'features/locale/view/desktop';
import Auth from 'features/Auth/view/desktop';
import Menu from 'components/Menu/desktop';

import './App.scss';

const b = block('app');

const App = ({ location }) => {
  const dispatch = useDispatch();

  const locale = useSelector(state => state.locale.locale);
  const isAuth = useSelector(state => state.auth.isAuth);
  const user = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(authAction.checkAuth());
  }, [dispatch]);

  return (
    <main className={b()}>
      <Notify />
      {isAuth && <Header />}
      {/* {isAuth && <Subheader user={user} getBalance={authAction.getBalance} />} */}
      {isAuth ? (
        <section className={b('main')}>
          <nav className={b('menu')}>
            <Menu isDesktop />
            <Footer locale={locale} isAuth={isAuth} />
          </nav>
          <section className={b('content')}>
            <Switch>
              <Route exact key="/user-create" path="/user-create" component={CreateUser} />
              <Route exact key="/users-list" path="/users-list" component={UsersList} />
              <Route exact key="/total" path="/total" component={Total} />
              <Route exact key="/user-edit/:id" path="/user-edit/:id" component={UserEdit} />
              <Route exact key="/transaction-history" path="/transaction-history" component={TransactionHistory} />
              <Route exact key="/change-pas" path="/change-pas" component={ChangePas} />
              <Route exact key="/tree" path="/tree" component={Tree} />
              <Route exact key="/casino-history" path="/casino-history" component={SlotsBetHistory} />
              <Route exact key="/bets" path="/bets" component={Bets} />
              <Route exact key="/cashier-jackpot" path="/cashier-jackpot" component={CashierJackpot} />
              <Route exact key="/locale" path="/locale" component={Locale} />
              <Route exact key="/change-child-pas" path="/change-child-pas/:id" component={ChangeChildPas} />
              <Route exact key="/" path="/">
                <section className={b('welcome')}>
                  <h3 className={b('welcome-title')}>{locale.welcomeToPanel}</h3>
                  {locale.useLeftMenu}
                </section>
              </Route>
            </Switch>
          </section>
        </section>
      ) : (
        <Auth />
      )}
      <AsyncPollBalance />
    </main>
  );
};

export default withRouter(App);
