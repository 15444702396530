export class TreeApiConverter {
  convertUsersById = ({ data, id, subusers }) => {
    return data.map(t => {
      const { balance, is_banned, players, role, subusers: subusersCount, user_id, username } = t;
      return {
        parentId: id,
        balance: balance,
        isBanned: is_banned,
        players,
        role,
        subusersCount,
        subusers,
        userId: user_id,
        username,
      };
    });
  };
}
