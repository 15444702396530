import React from 'react';
import block from 'bem-cn';
import PT from 'prop-types';

import { allProviders } from '../../../data';
import Select from 'components/Select/desktop';

import './ProviderFilter.scss';

export const ProviderFilter = props => {
  const b = block('provider-filter');

  const { locale, onChange, filters } = props;

  const gameTypes = [
    { name: locale.all, value: 'all' },
    { name: 'Slots', value: 'slots' },
    { name: 'Live Casino', value: 'live-casino' },
    { name: 'Sports', value: 'sports' },
  ];

  const isGameTypeAll = gameTypes[0].value === filters.gameType.value;

  const providers = allProviders
    .all()
    .map(el => ({ name: el, value: el }))
    .filter(provider =>
      isGameTypeAll ? provider : allProviders[filters.gameType.value].some(el => el === provider.name),
    )
    .reduce((acc, el) => [...acc, { ...el }], [{ name: locale.all, value: 'all' }]);

  const handleProviderChange = value => {
    const providerItem = providers.find(item => item.value === value);
    onChange({ ...filters, provider: providerItem });
  };

  const handleGameTypeChange = value => {
    const gameItem = gameTypes.find(item => item.value === value);
    onChange({ ...filters, gameType: gameItem });
  };

  return (
    <section className={b()}>
      <div className={b('filter-row')}>
        <p className={b('text')}>{locale.provider}:</p>
        <Select items={providers} activeItem={filters.provider} onChange={handleProviderChange} />
      </div>
      <div className={b('filter-row')}>
        <p className={b('text')}>{locale.gameType}:</p>
        <Select items={gameTypes} activeItem={filters.gameType} onChange={handleGameTypeChange} />
      </div>
    </section>
  );
};

ProviderFilter.propTypes = {
  locale: PT.object.isRequired,
  onChange: PT.func.isRequired,
  filters: PT.exact({
    provider: PT.exact({ name: PT.string, value: PT.string }),
    gameType: PT.exact({ name: PT.string, value: PT.string }),
    betType: PT.exact({ name: PT.string, value: PT.string }),
  }).isRequired,
};
