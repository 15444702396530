import React from 'react';
import block from 'bem-cn';
import PT from 'prop-types';
import Select from 'components/Select/desktop';

import './BetTypesFilter.scss';

const BetTypesFilter = props => {
  const b = block('bet-types-filter');
  const { locale, onChange, filters } = props;
  const betTypes = [
    { name: locale.all, value: 'all' },
    { name: locale.betTypes.bet, value: '2' },
    { name: locale.betTypes.win, value: '1' },
    { name: locale.betTypes.refund, value: '3' },
    // {name: locale.betTypes.returned, value: 'returned'},
    // {name: locale.betTypes.cashedOut, value: 'cashed_out'},
    // {name: locale.betTypes.lost, value: 'lost'},
    // {name: locale.betTypes.rollback, value: 'rollback'},
  ];

  const handleBetTypesChange = value => {
    const betType = betTypes.find(item => item.value === value);
    onChange({ ...filters, betType });
  };
  return (
    <section className={b()}>
      <div className={b('filter-row')}>
        <div className={b('text')}>{locale.betType}:</div>
        <Select items={betTypes} activeItem={filters.betType} onChange={handleBetTypesChange} />
      </div>
    </section>
  );
};

BetTypesFilter.propTypes = {
  locale: PT.object.isRequired,
  onChange: PT.func.isRequired,
  filters: PT.exact({
    provider: PT.exact({ name: PT.string, value: PT.string }),
    gameType: PT.exact({ name: PT.string, value: PT.string }),
    betType: PT.exact({ name: PT.string, value: PT.string }),
  }).isRequired,
};

export default BetTypesFilter;
