import React from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import SVG from 'components/SVG';
import { NotifyModel } from 'services/models/NotifyModel';

import CrossSVG from './img/cross.svg';
import defaultSVG from './img/default.svg';
import errorSVG from './img/error.svg';
import successSVG from './img/success.svg';

import './Notification.scss';

class Notification extends React.PureComponent {
  constructor(props) {
    super(props);
    this.notifyRef = React.createRef();
    this.state = {
      isDeleting: false,
      isDeleted: false,
    };
  }

  static propTypes = {
    text: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    needClose: PropTypes.bool.isRequired,
    deleteNotify: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (this.props.needClose) {
      this.startAutoClose();
    }
  }

  componentWillUnmount() {
    this.clearAutoClose();
  }

  startAutoClose = () => {
    this.timeout = setTimeout(this._hideNotify, 7000);
  }

  clearAutoClose = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  handleMouseEnter = () => {
  if (!this.state.isDeleting) { 
    this.clearAutoClose();  
  }
  };

  handleMouseLeave = () => {
    if (!this.state.isDeleting) { 
      this.startAutoClose();  
    }
  };

  render() {
    const b = block('notification');
    const { text, type } = this.props;
    return (
      <div
        className={b({ deleting: this.state.isDeleting }, { type })}
        ref={this.notifyRef}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <div className={b('wrapper')}>
          <SVG className={b('icon')} svgProps={{ svg: this._selectIcon(type) }} onClick={this._hideNotify} />
          <span className={b('text')}>{text}</span>
          <SVG className={b('cross')} svgProps={{ svg: CrossSVG }} onClick={this._hideNotify} />
        </div>
      </div>
    );
  }

  _selectIcon = type => {
    switch (type) {
      case 'error': return errorSVG;
      case 'success': return successSVG;
      default: return defaultSVG;
    }
  };

  _hideNotify = () => {
  const { deleteNotify, id } = this.props;
  if (!this.state.isDeleting) {
    this.setState({ isDeleting: true }, () => {
      setTimeout(() => {
        if (!this.state.isDeleted) { 
          this.setState({ isDeleted: true }, () => {
            deleteNotify(id);
          });
        }
      }, 600);
    });
  }
};

}

export default Notification;
