import React, { useMemo, useState } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { Link, useLocation } from 'react-router-dom';

import SVG from 'components/SVG';

import arrowSvg from './img/arrow.svg';

import './MenuItem.scss';

const b = block('menu-item-desktop');

const MenuItem = ({ locale, item, role = 1 }) => {
  const [isOpen, changeOpen] = useState(true);
  const location = useLocation();

  const itemList = useMemo(
    () =>
      item.items.map(temp => {
        if (temp.roles && !temp.roles.includes(+role)) return null;
        const isActive = location.pathname.startsWith(temp.route);

        return (
          <Link className={b('element', { active: isActive })} key={temp.textId} to={temp.route}>
            <span className={b('element-name')}>{locale[temp.textId]?.[role]}</span>
            <SVG className={b('element-arrow')} svgProps={{ svg: arrowSvg }} />
          </Link>
        );
      }),
    [item.items, locale, role, location],
  );

  return (
    <>
      <div
        className={b()}
        // onClick={() => changeOpen(!isOpen)}
      >
        <span className={b('name')}>{locale[item.textId]}</span>
      </div>
      {isOpen && <div className={b('sub-list')}>{itemList}</div>}
    </>
  );
};

export default MenuItem;
