import React from 'react';
import block from 'bem-cn';

import SVG from 'components/SVG';

import searchSvg from './img/search.svg';
import crossSVG from './img/cross.svg';

import './InputSearch.scss';

const b = block('input-search-desktop');

const InputSearch = ({ ...restProps }) => {
  return (
    <div className={b()}>
      <SVG className={b('search-icon')} svgProps={{ svg: searchSvg }} />
      <input {...restProps} className={b('input')} type="search" />
      <SVG className={b('cross')} svgProps={{ svg: crossSVG }} style={{ opacity: restProps.value ? 1 : 0 }} />
    </div>
  );
};

export default InputSearch;
