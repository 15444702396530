import dayjs from 'dayjs';
import { addNotify } from 'features/notify';

const actionTypes = {
  ACTION_PROCESSING: 'slotsHistory/ACTION_PROCESSING',
  GET_SLOTS_BET_HISTORY_SUCCESS: 'slotsHistory/GET_SLOTS_BET_HISTORY_SUCCESS',
};

function getSlotsBetHistory(info) {
  return async (dispatch, getState, extra) => {
    console.log(info);

    if (!info.target_id) return;
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const locale = getState().locale.locale;
    const response = await api.slotsBetHistory.getSlotsBetHistory(info);
    // eslint-disable-next-line no-prototype-builtins
    const errorMessage = /* errorStatusMessage */ locale.hasOwnProperty(response.codeStatus)
      ? /* errorStatusMessage */ locale[response.codeStatus]
      : /* errorStatusMessage */ locale.defaultMessage;
    if (response.success) {
      dispatch({ type: actionTypes.GET_SLOTS_BET_HISTORY_SUCCESS, payload: response.data });
    } else {
      dispatch(addNotify(errorMessage, 'error'));
    }
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
  };
}

export { actionTypes, getSlotsBetHistory };
