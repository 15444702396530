import React, { useState, useCallback } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Input from 'components/Input';
import Button from 'components/Button';

import { actions as usersActions } from 'features/users/redux';

import './CreateUser.scss';

const b = block('create-user');

const CreateUser = ({ history }) => {
  const dispatch = useDispatch();

  const [userName, changeUserName] = useState('');
  const [password, changePassword] = useState('');

  const role = useSelector(state => state.auth.role);
  const locale = useSelector(state => state.locale.locale);
  const actionProcessing = useSelector(state => state.users.actionProcessing);

  const onClick = useCallback(() => {
    if (!actionProcessing) dispatch(usersActions.createUser(userName, password, userId => history.replace('/')));
  }, [actionProcessing, dispatch, password, userName, history]);

  let headerText = locale[`newAgent${role}`];

  return (
    <div className={b()}>
      <div className={b('content')}>
        <h3 className={b('title')}>{headerText}</h3>
        <div className={b('field')}>
          <div className={b('field-title')}>{`${locale.userName}`}</div>
          <div className={b('field-input')}>
            <Input
              placeholder={locale.userName}
              value={userName}
              color="white"
              onChange={e => changeUserName(e.currentTarget.value)}
            />
          </div>
        </div>
        <div className={b('field')}>
          <div className={b('field-title')}>{`${locale.password}`}</div>
          <div className={b('field-input')}>
            <Input
              name="password"
              placeholder={locale.password}
              value={password}
              color="white"
              onChange={e => changePassword(e.currentTarget.value)}
            />
          </div>
        </div>
        <div className={b('button')}>
          <Button onClick={onClick} color="orange">
            <span className={b('button-text')}>{locale.create}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CreateUser);
