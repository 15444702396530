import React, { useState, useCallback } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';
import SVGInline from 'react-svg-inline';
import { Redirect } from 'react-router-dom';

import Button from 'components/Button';
import Input from 'components/Input';
import SVG from 'components/SVG';

import { actions as authActions } from 'features/Auth';
import Locale from 'features/locale/view/desktop';


import language from './img/lang.svg';
import arrowSvg from './img/arrow.svg';
import crossSVG from './img/cross.svg';

import './Auth.scss';

const b = block('auth');

const Auth = () => {
  const dispatch = useDispatch();

  const [userName, changeUserName] = useState('');
  const [password, passwordUserName] = useState('');

  const [isLoginPage, setIsLoginPage] = useState(true);

  const locale = useSelector(state => state.locale.locale);
  const { isProcess, isAuth } = useSelector(state => state.auth);

  const signIn = useCallback(() => {
    if (!isProcess) {
      dispatch(authActions.login(userName, password));
    }
  }, [userName, password, isProcess, dispatch]);

  if (isAuth) return <Redirect to="/" />;

  return (
    <article className={b()}>
      <section className={b('content')}>
        {isLoginPage ?
        <>
          <h1 className={b('title')}>{locale.role[2]}</h1>
          <div className={b('login')}>{locale.login}</div>
          <div className={b('field')}>
            <div className={b('field-title')}>{`${locale.userName}:`}</div>
            <div className={b('field-input')}>
              <Input
                value={userName}
                onChange={e => changeUserName(e.currentTarget.value)}
                name="login"
                placeholder={locale.login}
              />
            </div>
          </div>
          <div className={b('field')}>
            <div className={b('field-title')}>{`${locale.password}:`}</div>
            <div className={b('field-input')}>
              <Input
                value={password}
                onChange={e => passwordUserName(e.currentTarget.value)}
                name="password"
                type="password"
                placeholder={locale.password}
              />
            </div>
          </div>
          <div className={b('button')}>
            <Button onClick={signIn} color="orange">
              <span className={b('button-text')}>{locale.login}</span>
            </Button>
          </div>
          <div className={b('other')}>{locale.other}</div>
          <div className={b('item')} onClick={() => setIsLoginPage(false)}>
            <SVG svgProps={{ svg: language }} className={b('item-img')} />
            <span className={b('item-text')}>{locale.changeLang}</span>
            <SVG className={b('element-arrow')} svgProps={{ svg: arrowSvg }} />
          </div>
        </>
        : (
          <>
            <h3 className={b('header')}>
              {locale.changeLang}
              <div className={b('cross-container')} onClick={() => setIsLoginPage(true)}>
                <SVG svgClassName={b('cross')} svgProps={{ svg: crossSVG }} />
              </div>
            </h3>
            <section className={b('locale')}>
              <Locale forAuth callback={() => setIsLoginPage(true)} />
            </section>
          </>)}
      </section>
    </article>
  );
};

export default Auth;
