import React, { useState, useCallback, useEffect, useMemo } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import SVGInline from 'react-svg-inline';
import dayjs from 'dayjs';

import { actions as totalActions } from 'features/Total/redux';

import InputSearch from 'components/InputSearch/desktop';
import Input from 'components/Input';
import BackBlock from 'components/BackBlock/desktop';
import Spinner from 'components/Spinner';
import Button from 'components/Button';
import SVG from 'components/SVG';
import InputDate from 'components/InputDate/desktop';

import './Total.scss';

const b = block('total');

const Total = () => {
  const dispatch = useDispatch();

  const locale = useSelector(state => state.locale.locale);
  const totalInfo = useSelector(state => state.total.totalInfo);
  const name = useSelector(state => state.auth.name, shallowEqual);
  const role = useSelector(state => state.auth.role, shallowEqual);
  const userList = useSelector(state => state.total.users, shallowEqual);
  const actionProcessing = useSelector(state => state.total.actionProcessing);

  const [fromDate, changeFromDate] = useState(dayjs(new Date()).add(-3, 'day').format('YYYY-MM-DD'));
  const [toDate, changeToDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'));

  useEffect(() => {
    dispatch(totalActions.getTotal(fromDate, toDate));
  }, [dispatch, fromDate, toDate]);
  const callBack = useCallback(() => dispatch(totalActions.getTotal(fromDate, toDate)), [fromDate, toDate, dispatch]);

  const onUserClick = useCallback(
    id => {
      if (!actionProcessing) {
        dispatch(totalActions.getUserData(id, fromDate, toDate));
      }
    },
    [actionProcessing, dispatch, fromDate, toDate],
  );

  const users = useMemo(
    () =>
      userList.length === 1 && !!!userList[0].userId
        ? null
        : userList.map(t => (
            <div key={t.userId} className={b('row')} onClick={() => onUserClick(t.userId)}>
              <span className={b('td')}>{t.username}</span>
              <span className={b('td')}>{t.total}</span>
              <span className={b('td')}>{t.credits}</span>
              <span className={b('td')}>{t.debits}</span>
            </div>
          )),
    [onUserClick, userList],
  );

  return (
    <section className={b()}>
      <h3 className={b('title')}>{locale['/total']}</h3>
      <div className={b('content')}>
        <div className={b('fields')}>
          <div className={b('field-title')}>{`${locale.from}`}</div>
          <div className={b('field-title')}>{`${locale.to}`}</div>
          <div />
          <div className={b('field-input')}>
            <InputDate
              dateFormat="yyyy-MM-dd"
              selected={new Date(fromDate)}
              onChange={date => {
                changeFromDate(dayjs(date).format('YYYY-MM-DD'));
              }}
              isValid={Boolean(fromDate)}
              title="date title"
            />
          </div>
          <div className={b('field-input')}>
            {/* <Input value={toDate} onChange={e => changeToDate(e.currentTarget.value)} type="date" /> */}
            <InputDate
              dateFormat="yyyy-MM-dd"
              selected={new Date(toDate)}
              onChange={date => {
                changeToDate(dayjs(date).format('YYYY-MM-DD'));
              }}
              isValid={Boolean(toDate)}
              title="date title"
            />
          </div>
          <div className={b('button')}>
            <Button onClick={actionProcessing ? f => f : callBack} color="orange">
              <span className={b('button-text')}>{locale.display}</span>
            </Button>
          </div>
        </div>
      </div>
      <article className={b('result')}>
        <div className={b('result-header')}>
          <span className={b('td', { header: true })}>{locale.nickname}</span>
          <span className={b('td', { header: true })}>{locale.total}</span>
          <span className={b('td', { header: true })}>{locale.deposit}</span>
          <span className={b('td', { header: true })}>{locale.withdraw}</span>
        </div>
        {users}
        <div className={b('row')}>
          <span className={b('td')}>{locale.total}</span>
          <span className={b('td', { total: true })}>{totalInfo.total}</span>
          <span className={b('td', { total: true })}>{totalInfo.credits}</span>
          <span className={b('td', { total: true })}>{totalInfo.debits}</span>
        </div>
      </article>
    </section>
  );
};

export default Total;
